import axios, { AxiosError, AxiosInstance } from 'axios';
import { toastService } from './toast.service';
import dayjs from 'dayjs';
import { BASE_URL, BASIC_AUTH_PASSWORD, BASIC_AUTH_USERNAME } from 'constants/api-endpoints';

class Http {
  private axios!: AxiosInstance;
  private unauthorizedStatusCodes = [401, 402, 403];
  constructor() {
    this.initAxios();
  }

  private initAxios() {
    this.axios = axios.create({
      baseURL: BASE_URL,
      timeout: 30000
    });
    const errorHandler = (error: AxiosError<{ message: string; statusCode: number }>) => {
      if (error && error.response) {
        const { message, statusCode } = error.response.data;
        if (this.unauthorizedStatusCodes.includes(statusCode)) {
          toastService.showToast(message);
          //to logout after showing toast message
          setTimeout(() => {
            localStorage.clear();
            location.reload();
          }, 4000);
        } else {
          toastService.showToast(message);
        }
      } else {
        toastService.showToast(error.message);
      }
      return error;
    };
    this.axios.interceptors.request.use(async (config: any) => {
      if (config.headers) {
        const basicAuthToken = btoa(`${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`);
        const auth_token = localStorage.getItem('auth_token');
        config.headers['basic'] = `Basic ${basicAuthToken}`;
        if (auth_token && auth_token.length > 0) config.headers['Authorization'] = `Bearer ${auth_token}`;
        config.headers['basic'] = `Basic ${basicAuthToken}`;
        return config;
      }
      return config;
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        errorHandler(error);
        return error;
      }
    );
  }

  post<T = any>(url: string, data: any) {
    return this.axios.post<T>(url, data);
  }

  put<T = any>(url: string, data: any) {
    return this.axios.put<T>(url, data);
  }

  patch<T = any>(url: string, data: any) {
    return this.axios.patch<T>(url, data);
  }
  delete<T = any>(url: string) {
    return this.axios.delete<T>(url);
  }

  get<T = any>(url: string, httpParams?: any) {
    const updatedParams = this.parseDateToTimeStamp(httpParams);
    for (const item in httpParams) {
      if (httpParams[item] === '' || httpParams[item] === undefined || httpParams[item] === null) {
        delete httpParams[item];
      }
    }
    let finalParams: any;
    if (httpParams) {
      finalParams = updatedParams;
    }
    return this.axios.get<T>(url, { params: finalParams });
  }
  parseDateToTimeStamp(obj: any) {
    const newValueInstance = Object.assign({}, obj);
    (function isEmpty(data: any): boolean {
      if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data)) {
          data.forEach((item: any, index: number) => {
            if (isEmpty(item)) {
              data.splice(index, 1);
            }
          });
        } else {
          // eslint-disable-next-line array-callback-return
          Object.keys(data).map((key, index) => {
            if (data[key] instanceof Date) {
              data[key] = new Date(data[key]).toISOString();
            }
            // * Convert date to iso string and set hours accordingly
            if (data[key] instanceof dayjs) {
              if (key.includes('from')) {
                const date = new Date(data[key]);
                date.setDate(date.getDate() + 1);
                date.setUTCHours(0, 0, 0, 0);
                data[key] = date.toISOString();
              } else if (key.includes('to')) {
                const date = new Date(data[key]);
                date.setDate(date.getDate() + 2);
                date.setUTCHours(0, 0, 0, 0);
                // date.setUTCHours(23, 59, 59, 999);
                data[key] = date.toISOString();
              } else {
                data[key] = data[key].toDate().toISOString();
              }
            }
          });
        }
      }
      return data;
    })(newValueInstance);
    return newValueInstance;
  }
}
export const http = new Http();
